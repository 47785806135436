<template>
	<div>
		<HeaderPage/>
	<main>
	<section class="breadcrumbs">
      <div class="container">

        <ol>
          <li><a href="index.html">Home</a></li>
          <li>Container Freight Station</li>
        </ol>
        <h2>Vilsons CFS</h2>

      </div>
    </section>

    <!-- ======= Blog Section ======= -->
    <section id="blog" class="blog">
      <div class="container" data-aos="fade-up">
        <div class="row">
            <div class="col-lg-12 entries">
            <article class="entry">
                <div class="entry-title">
                    <h2><a href="/#/cfs">Custom Broking &amp; Warehousing &amp; CFS</a></h2><br/>
                </div>
                <div class="entry-img">
                    <img src="assets/img/blog/blog-1.jpg" alt="" class="img-fluid">
                </div>
                <div class="entry-content">
                    <p>Licensed Custom Broker, Custom Bonded Warehouse Operator. Our Facility is licensed by Government of India and services Export House, Traders, Merchandiser, Industries an Import/Export of Container Cargo Facilitating Cargo Examination, Warehousing.</p>
                    <div class="read-more">
                        <a href="/#/cfs">Read More</a>
                    </div>
                </div>
            </article>
            </div>
        </div>
    </div>
    </section>

  </main>
	</div>
</template>


<script>
import HeaderPage from '@/components/Header.vue';
export default {
  name: 'cfspage',
  components: {
    HeaderPage,
  },
}
</script>